const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
        api: "https://thamduyetpccc-api.cgis.asia/",
  },
  production: {
      api: "https://thamduyetpccc-api.cgis.asia/",
  },
};

module.exports = apiEnvironment[env];
